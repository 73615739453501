(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/funds/assets/javascripts/funds.js') >= 0) return;  svs.modules.push('/components/lb-utils/funds/assets/javascripts/funds.js');
"use strict";


let TTLCache;
let logger;
let jupiter;
let isTopptipset;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('lb-utils:funds');
  TTLCache = trinidad.ttlcache;
  jupiter = trinidad.jupiter;
  isTopptipset = trinidad.components.require('utils', 'products').helpers.isTopptipset;
} else {
  logger = new svs.core.log.Logger('lb-utils:funds');
  TTLCache = svs.components.lbUtils.ttlCache.TTLCache;
  jupiter = svs.core.jupiter;
  isTopptipset = svs.utils.products.helpers.isTopptipset;
}
const cacheId = 'currentFunds';
const cacheTime = 60000; 

const fundsCache = new TTLCache({
  ttl: cacheTime
});
const fundsConstants = {
  FUND_TYPE_ROLLOVER: 'Rollover',
  FUND_TYPE_ADJUST: 'Adjust' 
};

const ProcessFundsData = _ref => {
  let {
    fundsList
  } = _ref;
  const formattedList = [];
  for (let i = 0; i < fundsList.length; i++) {
    const productFunds = fundsList[i];
    let currentFund;
    if (productFunds.funds.length > 1) {
      currentFund = productFunds.funds.filter(fund => fund.source === fundsConstants.FUND_TYPE_ROLLOVER)[0] || productFunds.funds.filter(fund => fund.source === fundsConstants.FUND_TYPE_ADJUST)[0];
    } else {
      currentFund = productFunds.funds[0];
    }
    const isToptipsetProduct = isTopptipset(productFunds.productId);
    const productName = isToptipsetProduct ? 'Topptipset' : productFunds.productName;
    if (currentFund) {
      formattedList.push({
        productId: productFunds.productId,
        productName,
        drawNumber: productFunds.drawNumber,
        closeTime: productFunds.closeTime,
        fundsAmount: currentFund.amount,
        type: currentFund.source
      });
    }
  }
  return formattedList;
};

const fetchFundsFromAPI = async req => {
  let data;
  const _options = {
    method: 'GET',
    path: '/draw/1/funds'
  };
  if (svs.isServer) {
    _options.req = req;
    data = await jupiter.callAsync(_options);
  } else {
    data = await jupiter.call(_options);
  }
  if (data.error) {
    throw new Error(data.error);
  }
  if (data.funds && data.funds.length) {
    return ProcessFundsData({
      req,
      fundsList: data.funds
    });
  }
  logger.info('No funds found!', req);
  return [];
};

const getFundsFromCache = async req => {
  const cachedFunds = fundsCache.get(cacheId);
  if (cachedFunds) {
    return JSON.parse(cachedFunds);
  }
  const fetchedFunds = await fetchFundsFromAPI(req);
  fundsCache.set(cacheId, JSON.stringify(fetchedFunds), cacheTime);
  return fetchedFunds;
};

const getFunds = async input => {
  const {
    req,
    product,
    products = []
  } = input || {};
  if (product) {
    products.push(product);
  }
  try {
    const funds = await getFundsFromCache(req);
    if (products.length) {
      let filteredFunds = [];
      for (let i = 0; i < products.length; i++) {
        const fundsList = funds.filter(fund => {
          if (typeof products[i] === 'string') {
            return replaceSweChars(fund.productName.toLowerCase()) === replaceSweChars(products[i]).toLowerCase();
          } else if (typeof products[i] === 'number') {
            return fund.productId === products[i];
          }
          return false;
        });
        filteredFunds = filteredFunds.concat(fundsList);
      }
      return filteredFunds;
    }
    return funds;
  } catch (err) {
    logger.error(err, req);
    throw err;
  }
};
const replaceSweChars = stringToFix => stringToFix.replace('å', 'a').replace('ä', 'a').replace('ö', 'o');
if (svs.isServer) {
  module.exports = {
    getFunds,
    fundsConstants
  };
} else {
  setGlobal('svs.components.lbUtils.funds', {
    getFunds,
    fundsConstants
  });
}

 })(window);